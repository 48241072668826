var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        {
          staticClass: "pt-0 page-background mainArea",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("TopBar", { attrs: { title: "Contact Book" } }),
          _c(
            "PageLayout",
            { attrs: { minWidth: "600px" } },
            [
              _c("v-data-table", {
                staticClass: "cursor-pointer",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.contacts,
                  "hide-default-footer": "",
                  search: _vm.search,
                  "custom-filter": _vm.filterContacts,
                  "disable-pagination": "",
                },
                on: {
                  "click:row": function ($event) {
                    _vm.selectedContactId = $event.site_contact_id
                    _vm.showEditContactDialog = true
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "v-row",
                            { staticClass: "pa-0 ma-0" },
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between align-end gap px-2",
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.filteredContacts,
                                        label: "Search",
                                        "hide-details": "auto",
                                        color: "primary",
                                        name: "search",
                                        "item-value": "site_contact_id",
                                        "search-input": _vm.searchContact,
                                        "no-filter": "",
                                        "hide-no-data": "",
                                      },
                                      on: {
                                        "update:searchInput": function (
                                          $event
                                        ) {
                                          _vm.searchContact = $event
                                        },
                                        "update:search-input": function (
                                          $event
                                        ) {
                                          _vm.searchContact = $event
                                        },
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c("v-icon", [
                                                _vm._v(_vm._s(_vm.mdiMagnify)),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "item",
                                          fn: function ({ item }) {
                                            return [
                                              _c("section", [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(item.f_name) +
                                                      " " +
                                                      _vm._s(item.l_name)
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  { staticClass: "caption" },
                                                  [_vm._v(_vm._s(item.email))]
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "selection",
                                          fn: function ({ item }) {
                                            return [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(item.f_name) +
                                                    " " +
                                                    _vm._s(item.l_name)
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.search,
                                        callback: function ($$v) {
                                          _vm.search = $$v
                                        },
                                        expression: "search",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { color: "primary" },
                                            on: {
                                              click: function ($event) {
                                                _vm.showAddContactDialog = true
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                " " + _vm._s(_vm.mdiPlus) + " "
                                              ),
                                            ]),
                                            _vm._v(" Add Contact "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "secondary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.selectedContactId =
                                        item.site_contact_id
                                      _vm.showEditContactDialog = true
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(" " + _vm._s(_vm.mdiPencil) + " "),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "#F2545B" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.idOfContactToDelete =
                                        item.site_contact_id
                                      _vm.showConfirmRemoveContactDialog = true
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(" " + _vm._s(_vm.mdiDelete) + " "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm.showEditContactDialog
                ? _c("EditContactDialog", {
                    attrs: {
                      showEditContactDialog: _vm.showEditContactDialog,
                      selectedContactId: _vm.selectedContactId,
                      contacts: _vm.contacts,
                    },
                    on: {
                      "edit-contacts-dialog-close": function ($event) {
                        _vm.showEditContactDialog = false
                      },
                      "contact-updated": function ($event) {
                        _vm.showEditContactDialog = false
                        _vm.getContacts()
                      },
                    },
                  })
                : _vm._e(),
              _vm.showAddContactDialog
                ? _c("AddContactDialog", {
                    attrs: {
                      showAddContactDialog: _vm.showAddContactDialog,
                      contacts: _vm.contacts,
                    },
                    on: {
                      "add-contacts-dialog-close": function ($event) {
                        _vm.showAddContactDialog = false
                      },
                      "contact-added": function ($event) {
                        _vm.showAddContactDialog = false
                        _vm.getContacts()
                      },
                    },
                  })
                : _vm._e(),
              _c("ConfirmRemoveContactDialog", {
                attrs: {
                  showConfirmRemoveContactDialog:
                    _vm.showConfirmRemoveContactDialog,
                },
                on: {
                  cancel: function ($event) {
                    _vm.showConfirmRemoveContactDialog = false
                  },
                  "remove-contact": _vm.removeContact,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 page-background mainArea">
      <TopBar title="Contact Book" />
      <PageLayout minWidth="600px">
        <v-data-table
          :headers="headers"
          :items="contacts"
          hide-default-footer
          :search="search"
          :custom-filter="filterContacts"
          disable-pagination
          class="cursor-pointer"
          @click:row="
            selectedContactId = $event.site_contact_id;
            showEditContactDialog = true;
          "
        >
          <template v-slot:top>
            <v-row class="pa-0 ma-0">
              <v-col cols="12">
                <div class="d-flex justify-space-between align-end gap px-2">
                  <v-autocomplete
                    :items="filteredContacts"
                    label="Search"
                    hide-details="auto"
                    color="primary"
                    name="search"
                    item-value="site_contact_id"
                    v-model="search"
                    :search-input.sync="searchContact"
                    no-filter
                    hide-no-data
                  >
                    <template v-slot:append>
                      <v-icon>{{ mdiMagnify }}</v-icon>
                    </template>

                    <template v-slot:item="{ item }">
                      <section>
                        <div>{{ item.f_name }} {{ item.l_name }}</div>
                        <div class="caption">{{ item.email }}</div>
                      </section>
                    </template>

                    <template v-slot:selection="{ item }">
                      <div>{{ item.f_name }} {{ item.l_name }}</div>
                    </template>
                  </v-autocomplete>

                  <div>
                    <v-btn color="primary" @click="showAddContactDialog = true">
                      <v-icon>
                        {{ mdiPlus }}
                      </v-icon>
                      Add Contact
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-btn
                icon
                color="secondary"
                @click="
                  selectedContactId = item.site_contact_id;
                  showEditContactDialog = true;
                "
              >
                <v-icon>
                  {{ mdiPencil }}
                </v-icon>
              </v-btn>

              <v-btn
                icon
                color="#F2545B"
                @click.stop="
                  idOfContactToDelete = item.site_contact_id;
                  showConfirmRemoveContactDialog = true;
                "
              >
                <v-icon>
                  {{ mdiDelete }}
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>

        <EditContactDialog
          :showEditContactDialog="showEditContactDialog"
          :selectedContactId="selectedContactId"
          :contacts="contacts"
          v-if="showEditContactDialog"
          @edit-contacts-dialog-close="showEditContactDialog = false"
          @contact-updated="
            showEditContactDialog = false;
            getContacts();
          "
        />

        <AddContactDialog
          :showAddContactDialog="showAddContactDialog"
          :contacts="contacts"
          v-if="showAddContactDialog"
          @add-contacts-dialog-close="showAddContactDialog = false"
          @contact-added="
            showAddContactDialog = false;
            getContacts();
          "
        />

        <ConfirmRemoveContactDialog
          :showConfirmRemoveContactDialog="showConfirmRemoveContactDialog"
          @cancel="showConfirmRemoveContactDialog = false"
          @remove-contact="removeContact"
        />
      </PageLayout>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import AddContactDialog from "@/components/contact-book/AddContactDialog";
import EditContactDialog from "@/components/contact-book/EditContactDialog";
import ConfirmRemoveContactDialog from "@/components/contact-book/ConfirmRemoveContactDialog";
import { mdiMagnify, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "First Name",
    align: "start",
    sortable: false,
    value: "f_name",
  },
  {
    text: "Last Name",
    align: "start",
    sortable: false,
    value: "l_name",
  },
  {
    text: "Title",
    align: "start",
    sortable: false,
    value: "title",
  },
  {
    text: "Company",
    align: "start",
    sortable: false,
    value: "company",
  },
  {
    text: "Email",
    align: "start",
    sortable: false,
    value: "email",
  },
  {
    text: "Phone Number",
    align: "start",
    sortable: false,
    value: "phone_no",
  },
  {
    text: "",
    align: "end",
    sortable: false,
    value: "actions",
  },
];

export default {
  name: "ContactBook",
  components: {
    TopBar,
    AddContactDialog,
    EditContactDialog,
    ConfirmRemoveContactDialog,
    PageLayout,
  },
  data() {
    return {
      headers,
      mdiMagnify,
      mdiDelete,
      mdiPencil,
      mdiPlus,
      search: "",
      contacts: [],
      showAddContactDialog: false,
      showEditContactDialog: false,
      selectedContactId: undefined,
      idOfContactToDelete: undefined,
      showConfirmRemoveContactDialog: false,
      contactChoices: [],
      searchContact: undefined,
    };
  },
  computed: {
    filteredContacts() {
      const { searchContact } = this;
      if (!searchContact) {
        return [];
      }
      return this.contacts.filter((c) => {
        return (
          c.email.includes(searchContact) ||
          c.f_name.includes(searchContact) ||
          c.l_name.includes(searchContact)
        );
      });
    },
  },
  methods: {
    async removeContact() {
      await axios.delete(`${APIURL}/contacts/${this.idOfContactToDelete}`);
      this.showConfirmRemoveContactDialog = false;
      await this.getContacts();
    },
    async getContacts() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/contacts`);
      this.contacts = results;
    },
    filterContacts(value, search) {
      const contact = this.contacts.find((c) => c.site_contact_id === search);
      const { email, f_name: fName, l_name: lName } = contact;
      return (
        typeof value === "string" &&
        (value.toString().toLowerCase().includes(email?.toLowerCase()) ||
          value.toString().toLowerCase().includes(fName?.toLowerCase()) ||
          value.toString().toLowerCase().includes(lName?.toLowerCase()))
      );
    },
  },
  beforeMount() {
    this.getContacts();
  },
};
</script>

<style>
.gap {
  gap: 10px;
}
</style>
